import React, { useEffect, useState, useContext }  from "react"
import { Container, 
  Row, 
  Col, 
  Button, 
  Dropdown, 
  DropdownButton, 
  Nav, 
  Tab } from 'react-bootstrap'
import { BsListStars } from "react-icons/bs"
import { IoMdClose } from "react-icons/io"
import { FaCog } from "react-icons/fa"
import axios from 'axios'
import I18n from '../../../i18n-js/locales.js'
import { ActivityDashboardContext } from '../../../utils/context.js'
import { appConstants } from '../../../utils/constants.js'
import ActivityDetails from "./ActivityDetails.js"
import ActivityImplementationPlan from "./ActivityImplementationPlan.js"
import ActivityMel from "./ActivityMel.js"
import ActivityPurpose from "./ActivityPurpose.js"
import ActivityDocuments from "./ActivityDocuments.js"
import ActivityNotes from "./ActivityNotes.js"
import ActivityLocations from "./ActivityLocations.js"
import ActivityDeliverables from "./ActivityDeliverables.js"
import ActivityKpis from "./ActivityKpis.js"

export default function  Activity() {
  const activityDashboardContext = useContext(ActivityDashboardContext)
  const [activity, setActivity] = useState({})
  const [activityNotes, setActivityNotes] = useState([])
  const [activityDeliverables, setActivityDeliverables] = useState([])
  const [resources, setResources] = useState([])
  const [grantThemes, setGrantThemes] = useState([])
  const [sites, setSites] = useState([])
  const [mapCenter, setMapCenter] = useState([18.56, -72.335])
  const [mapZoom, setMapZoom] = useState(12)
  const [canEdit, setCanEdit] = useState(false) 
  const [canAddNote, setCanAddNote] = useState(false)

  const fetchActivity = async () => {
    const res = await axios.get(`${appConstants.ACTIVITIES_URL}/${activityDashboardContext.activityId}.json`)
    setActivity(res.data)
    setActivityNotes(res.data.activity_notes)
    setActivityDeliverables(res.data.activity_deliverables)
    setResources(res.data.resources)
    setGrantThemes(res.data.grant_themes)
    setSites(res.data.sites)
    setCanEdit(res.data.can_edit)
    setCanAddNote(res.data.can_add_note)
  }

  useEffect(() => {
    fetchActivity()
  }, [activityDashboardContext.activityId])

  const renderGrantLinks = () => {
    return activity.activity_type == 'Grant' ? (
      <>
        <Nav.Link eventKey="implementation" className="border-bottom">{I18n.t('activerecord.attributes.activity.implementation_plan')}</Nav.Link>
      </>
    ) : ""

  }

  const renderEditControls = () => {
    return canEdit ? (
      <DropdownButton 
        id="dropdown-basic-button" 
        variant="outline-primary"
        size="sm"
        title={<FaCog />}>
        <Dropdown.Item href={`${appConstants.ACTIVITIES_URL}/${activityDashboardContext.activityId}/edit`} data-turbo-frame='modal'>{I18n.t('labels.edit')}</Dropdown.Item>
        <Dropdown.Item href={`${appConstants.ACTIVITY_DELIVERABLES_URL}/new?activity_id=${activityDashboardContext.activityId}`} data-turbo-frame='modal'>{I18n.t('helpers.navigate.add', {model: 'Deliverable'})}</Dropdown.Item>
        <Dropdown.Item  href={`${appConstants.SITES_URL}/new?activity_id=${activityDashboardContext.activityId}`} data-turbo-frame='modal'>{I18n.t('helpers.navigate.add', {model: 'Location'})}</Dropdown.Item>
        <Dropdown.Item  href={`${appConstants.ACTIVITY_NOTES_URL}/new?activity_id=${activityDashboardContext.activityId}`} data-turbo-frame='modal'>{I18n.t('helpers.navigate.add', {model: 'Activity Note'})}</Dropdown.Item>
        <Dropdown.Item  href={`${appConstants.RESOURCES_URL}/new?activity_id=${activityDashboardContext.activityId}`} data-turbo-frame='modal'>{I18n.t('helpers.navigate.add', {model: 'Document'})}</Dropdown.Item>
        <Dropdown.Item href={`${appConstants.ACTIVITIES_URL}/${activityDashboardContext.activityId}`} data-turbo-method="delete" data-turbo-confirm={I18n.t('warnings.are_you_sure')}><span className="text-danger">{I18n.t('labels.delete')}</span></Dropdown.Item>
      </DropdownButton> 
    ) : canAddNote ? (
      <DropdownButton 
        id="dropdown-basic-button" 
        variant="outline-primary"
        size="sm"
        title={<FaCog />}>
        <Dropdown.Item  href={`${appConstants.ACTIVITY_NOTES_URL}/new?activity_id=${activityDashboardContext.activityId}`} data-turbo-frame='modal'>{I18n.t('helpers.navigate.add', {model: 'Activity Note'})}</Dropdown.Item>
      </DropdownButton> 
    ) : ""
  }

  return (
    <Container>
      <Row>
        <Col md={3} className="border-end">
          <Row className="row border-bottom pb-2 me-1">
            <Col className="col fs-5"><BsListStars /> Sections</Col>
            <Col className="col d-flex flex-row-reverse available-tags">
              {renderEditControls()}
            </Col>
          </Row>

        </Col>
        <Col md={9}>
          <Row className="border-bottom pb-2">
            <Col md={10}>
              <span className="fs-5">{activity.name} &nbsp;</span>
            </Col>
            <Col md={2} className="col d-flex flex-row-reverse available-tags">
              <Button variant="outline-secondary" size="sm" onClick={()=>activityDashboardContext.setActivityId(null)}><IoMdClose /></Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Tab.Container id="activity-details" defaultActiveKey="purpose">
          <Col md={3} className="border-end">
            <Row>
              <Col className="pt-2">
                <Nav variant="underline" className="flex-column">
                  <Nav.Link eventKey="purpose" className="border-bottom">{I18n.t('activerecord.attributes.activity.purpose')}</Nav.Link>
                  <Nav.Link eventKey="details" className="border-bottom">{I18n.t('activerecord.attributes.activity.details')}</Nav.Link>
                  <Nav.Link eventKey="locations" className="border-bottom">{I18n.t('activerecord.attributes.activity.locations')}</Nav.Link>
                  <Nav.Link eventKey="notes" className="border-bottom">{I18n.t('activerecord.attributes.activity.notes')}</Nav.Link>
                  <Nav.Link eventKey="deliverables" className="border-bottom">{I18n.t('activerecord.attributes.activity.deliverables')}</Nav.Link>
                  <Nav.Link eventKey="documents" className="border-bottom">{I18n.t('activerecord.attributes.activity.documents')}</Nav.Link>
                  <Nav.Link eventKey="mel" className="border-bottom">{I18n.t('activerecord.attributes.activity.mel')}</Nav.Link>
                  <Nav.Link eventKey="kpis" className="border-bottom">{I18n.t('activerecord.attributes.activity.kpis')}</Nav.Link>
                  {renderGrantLinks()}
                </Nav>  
              </Col>
            </Row>
          </Col>
          <Col md={9}>
            <Tab.Content>
              <Tab.Pane eventKey="purpose">
                <ActivityPurpose activity={activity} />
              </Tab.Pane>
              <Tab.Pane eventKey="details">
                <ActivityDetails activity={activity} grantThemes={grantThemes} canEdit={canEdit} />
              </Tab.Pane>
              <Tab.Pane eventKey="locations">
                <ActivityLocations sites={sites} canEdit={canEdit} mapCenter={mapCenter} mapZoom={mapZoom} setMapCenter={setMapCenter} setMapZoom={setMapZoom} resetCoordinates={[18.56, -72.335]} resetZoom={12} />
              </Tab.Pane>
              <Tab.Pane eventKey="implementation">
                <ActivityImplementationPlan activity={activity} />
              </Tab.Pane>
              <Tab.Pane eventKey="deliverables">
                <ActivityDeliverables activityDeliverables={activityDeliverables} canEdit={canEdit} />
              </Tab.Pane>
              <Tab.Pane eventKey="notes">
                <ActivityNotes activityNotes={activityNotes} canEdit={canEdit} />
              </Tab.Pane>
              <Tab.Pane eventKey="mel">
                <ActivityMel activity={activity} />
              </Tab.Pane>
              <Tab.Pane eventKey="documents">
                <ActivityDocuments resources={resources} canEdit={canEdit} />
              </Tab.Pane>
              <Tab.Pane eventKey="kpis">
                <ActivityKpis activity={activity} />
              </Tab.Pane>
            </Tab.Content>
            <Row className="border-top pt-2">
              <Col md={6}>
                <Button variant="outline-secondary" onClick={()=>activityDashboardContext.setActivityId(null)}>{I18n.t('labels.back')}</Button>
              </Col>
              <Col md={6}>
              </Col>
            </Row>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  )
}

