import React from 'react'
import { Row, Col } from 'react-bootstrap'
import I18n from '../../../i18n-js/locales.js'

export default function ActivityKpis(props) {
  return (
    <>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_0_0_3')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_0_0_3}</dd>
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_2_0_2')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_2_0_2}</dd>
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_2_0_3')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_2_0_3}</dd>
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_2_0_5')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_2_0_5}</dd>
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_2_0_6')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_2_0_6}</dd>
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_3_0_3')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_3_0_3}</dd>
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_3_0_4')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_3_0_4}</dd>
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_3_0_5')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_3_0_5}</dd>
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col md={11} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.kpi_3_0_6')}</dt>
        </Col>
        <Col md={1}>
          <dd>{props.activity.kpi_3_0_6}</dd>
        </Col>
      </Row>
     
    </>
  )
}
